.segment.off {
  border-top: 2px solid #6495ed;
  padding: 0;
}

.segment.on {
  border-top: 2px solid green;
  padding: 0;
}

.line {
  padding-left: 1em !important;
}

.line.name {
  width: 35%;
  font-weight: bold;
}

.line.regime {
  width: 35%;
}

.line-list {
  margin: 0 !important;
  padding: 0 !important;
}


@media only screen and (max-width: 767px) {
  /* .ui.table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .ui.table td {
    padding: 0.5em 0.5em !important;
  } */

  /* .segment.off,
  .segment.on {
    padding: 0 !important;
  }
   */
   .segment.on {
    padding: 0px !important;
    /* background-color: #6495ed !important */
 }
}