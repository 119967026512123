/* .employee-data-visual{
  width: 50% !important;
} */

.factory-info {
  padding: 1em !important;
}

.ui.card>.content>.description, 
.ui.card>.content>.description {
  clear: right !important;
}

@media only screen and (max-width: 360px) {
  .employee-data-visual .content{
    padding: .5em .5em 0em .5em !important;   
  }

}