/* .report {
  display: flex !important;
} */

/* .monitor-setting.one {
  width: 425px !important;
  height: 550px;
} */


.report .setting .button {
  text-align: right;
}

.report .result::after {
  display: none !important;
}

.report .result ::before {
  display: none !important;
}
/* .report .result{
  color: red!important;
} */

/* .report table {
  height: 600px;
  overflow-y: auto;
} */


@media (max-width: 360px), (max-width: 767px) {
  .uspd-report {
    padding: .3em !important;
    font-size: 12px !important;
  }

  .report-header {
    padding-top: 1em !important;
  }
}

