/* 
@media only screen and (max-width: 767px) {
  .ui.table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .ui.table td {
    padding: 0.5em 0.5em !important;
  }
} */

.line-list-accordion .active {
  color: #2185d0 !important;
  background-color: aliceblue !important;
  padding: 0px !important;
}