.table-data {
  text-align: center !important;
  margin-bottom: 30px !important;
}


@media only screen and (max-width: 360px) {
  .table-data {
    font-size: 12px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    text-align: center !important;
  }

  .table-data.table th {
    padding: 0.7em 0.7em !important;
  }
  .table-data.table td {
    padding: 0.5em 0.5em !important;
  }

}