.login-form .column {
    margin: auto;
    margin-top: 10%;
}

.login-form .image {
    margin: 0;
    width: 50px !important;
}

.login-form .segment {
    box-shadow: 0 1px 10px 0 #6495ed;
}

.login-form.error .segment {
    box-shadow: 0 1px 10px 0 red;
}

.login-form .button {
    height: 43px !important;
    color: #6495ed;
    background: white;
    border: 1px solid;
}
.login-form .button:hover {
    color: white;
    background: #6495ed;
}

.login-form .button:focus {
    color: white;
    background: #6495ed;
}

.login-form .loading.button {
    background: #6495ed;
}

.login-form .header {
    color: #18b866;
}
