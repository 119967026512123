.table-data thead th {
    width: 20%;
}

.modules td {
    width: 50%;
}

/* .table-data {
    margin-bottom: 2em !important;
} */
