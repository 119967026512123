.date-picker{
    height: 36px!important;
}

.date-selector .item{
    width: auto!important;
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.date-selector ::before{
    display: none!important;
}