.app {
  display: flex !important;
}

.app.one {
  width: 425px !important;
  height: 550px;
}

.app.two {
  margin-left: auto;
  margin-right: auto;
  display: inline !important;
  text-align: center;
  width: auto !important;
}

.app .setting .button {
  text-align: right;
}

.app .result::after {
  display: none !important;
}

.app .result ::before {
  display: none !important;
}
/* .report .result{
  color: red!important;
} */

.app table {
  height: 600px;
  overflow-y: auto;
}