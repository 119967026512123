
.header-select {
  font-size: 16px !important;
}

@media only screen and (max-width: 767px) {
  [class*="production-report"] {
    font-size: 12px !important;
  }

  .production-report.segment {
    padding: 0.3em !important;
    height: auto !important;;
    overflow:  hidden !important;
  }

}
