.ui.button.toggle.active.active, .ui.button.toggle.active:active{
  background: #3385d0 !important;
  color:  white !important;
}

.btn-settings {
  margin: 7px !important;
  font-size: 15px !important;
}

.mobile-tab-data {
  padding: 0px !important;
  margin: 0px !important;
  min-height: 900px !important;
  overflow:  hidden !important;
}

.current-data {
  height: auto !important;
  overflow:  hidden !important;
}

.content-tab {
  overflow:  hidden !important;
  height:  auto !important;
  min-height: 900px !important;
}

.side-pushable {
  margin-top: 0px !important;
} 

.computer-data {
  padding: 0px !important;
}

.side-menu{
  padding: 0px !important;
  height:  auto !important;
}

.side-menu .item::before{
  display: none  !important;
}

.monitor-page-content {
  overflow:  hidden !important;
  height: auto !important;
  margin-bottom: 1em !important;
  display: block !important;
}


.monitor-page-content .row{
  margin: 0px !important;
  padding-bottom: 0px !important;
}

.mobile-data{
  margin: 0px !important;
  padding: 0px !important;  
}

.mobile-data .pusher{
  padding-right: 4px !important;
  width: 100% !important;
}


@media only screen and (max-width: 360px) {
  [class*="computer-data"] {
    display: none !important;
  }

  .table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .table td {
    padding: 0.5em 0.5em !important;
  }

}

@media only screen and (min-width: 1000px) {
  .row-mobile,
  .mobile-data {
    display: none !important;
  }
}

@media only screen and (max-width: 999px) {
  [class*="computer-data"] {
    display: none !important;
  }
  
  /* .ui.segment {
    padding: .3em !important;
    margin: 0px !important;
  } */

}
