.get-report-buttons {
  padding-top: 1em !important;
}

.get-report-buttons .button{
  padding-left: .5em !important;
  padding-right: .5em !important;
  flex-wrap: wrap !important; 
}
.clear-button {
  max-width: 6em !important;
}

.download-button {
  max-width: 2.5em !important;
}

@media only screen and (max-width: 600px) {
  .get-report-buttons .button{
    padding-left: .8em !important;
    padding-right: .8em !important;
  }
  .get-report-buttons {
    padding-top: 2em !important;
    padding-bottom: 1em !important;
    /* background-color: blue !important; */
  }
}

@media (max-width: 360px), (max-width: 767px) {
  .get-report-buttons {
    max-width: 250px !important;
  }
  
}