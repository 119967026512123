
@media only screen and (max-width: 360px) {
  [class*="map"] {
    display: none !important;
  }

  /* .table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .table td {
    padding: 0.5em 0.5em !important;
  } */

}

@media only screen and (max-width: 600px) {
  [class*="map"] {
    display: none !important;
  }
}


/* @media only screen and (max-width: 767px) {
  [class*="monitor-factory"] {
    display: none !important;
  }
} */

/* $(window).resize(function () {
  if (window.innerWidth < 600) { //Some arbitrary mobile width
     $(".sidebar").addClass('top').removeClass('right');
  } else {
     $(".sidebar").removeClass('top').addClass('right');
  }
}); */

/* @media only screen and (max-width: 767px) {
  [class*="mobile hidden"],
  [class*="current-data"]:not(.mobile),
  [class*="computer only"]:not(.mobile),
  [class*="large monitor only"]:not(.mobile),
  [class*="widescreen monitor only"]:not(.mobile),
  [class*="or lower hidden"] {
    display: none !important;
  }
} */

/* 
.search-panel {
  padding: 10px;
} */