.list .item.active {
  color: #2185d0 !important;
  background-color: aliceblue !important;
}

/* .list .item {
  padding-top: 10px !important;
  padding-left: 12px !important;
} */

@media only screen and (max-width: 360px) {
  [class*="factory-list-item"] {
    padding-top: 5px !important;
    padding-left: 7px !important;
  }
  [class*="factory-list"] {
    padding: 0.1em 0.2em !important; 
    overflow: auto !important;
    max-height: 290px !important; 
  }
}

.active-header {
  color: #216eb6  !important;
}

.factory-list {
  padding: 0.5em 0.5em !important;
} 

@media only screen and (min-width: 361px) {
  [class*="factory-list"] {
    overflow: auto !important;
    max-height: 700px !important; 
  }
}
