.ui.navbar.menu {
  margin: 0;
  width: 100%;
  box-shadow: 0 1px 8px 0 #6495ed;
}

.dd-button-menu{
  font-size: 16px !important;
}

.secondary.navbar.menu .item {
  padding: 0.5em;
}

.secondary.navbar.menu .image {
  height: 35px;
}

.secondary.navbar.menu .label {
  padding-bottom: 0;
}

.secondary.navbar.menu .label h3 {
  margin-bottom: 5%;
}

.secondary.navbar.menu .action {
  padding: 0;
}

.secondary.navbar.menu .action .button {
  min-width: 100px;
  width: 100%;
  height: 37px;
  font-weight: bolder !important;
}

.secondary.navbar.menu .button-menu .item {
  background-color: white !important;
  color: #3385d0 !important;
}

.dd-button-menu .item{
  min-width: 150px;
}

.secondary.navbar.menu .monitoring .active{
  padding: 0.5em;
  background-color:#5badff;
  color: white;
}

.secondary.navbar.menu .reports .active{
  padding: 0.5em;
  background-color:#5badff;
  color: white;
}

.choice-tab .select-button {
  background-color:#3385d0 !important;
  border: 1px solid #3385d0; /* Параметры рамки */
  color: white !important;
  /* border-top-right-radius: 0 !important; */
  /* border-bottom-right-radius: 0 !important; */
}

.choice-tab .unselect-button {
  color: #3385d0 !important;
  background-color: white !important;
  border: 1px solid #3385d0 !important; /* Параметры рамки */
  font-weight: normal;
  /* border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; */
}

/* .button {
  background-color: blue !important;
  color: white !important;
} */

@media only screen and (max-width: 767px) {
  [class*="choice-tab"],
  [class*="logout-action"] {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) {
  [class*="button-menu"] {
    display: none !important;
  }
}