/* .mode-checkbox{
  margin: 0em .5em .5em 0em!important;
} */

.monitor-setting.two {
  margin-left: auto;
  margin-right: auto;
  display: inline !important;
  /* text-align: center; */
  width: auto !important;
}

.monitor-setting {
  padding-left: 1em !important;
}

.monitor-setting .grid .column{
  width: 40% !important;
}

.monitor-setting .grid {
  padding: 0px !important;
}

.monitor-setting .accordion {
  margin: 0px !important;
}



@media (max-width: 999px) {
  .uspd-report-settings {
    min-height: 340px !important;
  }

}

@media (max-width: 360px), (max-width: 767px) {
  .monitor-setting{
    padding-left: .5em !important;
  }

  .uspd-report-settings {
    padding: 0.3em !important;
    height: auto !important;
    min-height: 340px !important;
    overflow:  hidden !important;
    margin: 0em !important;
  }

  .select-regims .field {
    margin-bottom: 0px !important;
  }

  .mode-checkbox {
    padding-bottom: .4em !important; 
  }

  .monitor-setting .grid>.column:not(.row),
  .monitor-setting .stackable.grid>.column:not(.row) {
    padding-top: .3em !important;
    padding-bottom: .3em !important;
  }

  .monitor-setting-button {
    padding-top: 1em !important;
    padding-bottom: .5em !important;
  }

  .select-uspd .dropdown{
    max-width: 250px !important;
  }

  /* .get-button-computer{
    display: none !important;
  } */
}


@media only screen and (min-width: 450px) {
  .monitor-setting .grid {
    flex-wrap: nowrap !important;  
  }

  .get-button-mobile{
    display: none !important;
  }

}

@media only screen and (max-width: 450px) {
  .monitor-setting .grid {
    flex-wrap: wrap !important;  
  }
 
  .select-group .field{
    margin-top: .5em !important;
    margin-bottom: .5em !important;
  } 
   .get-button-computer{
    display: none !important;
  }

}