.software{
    display: block!important;
}

.software-info{
    width: 100%;
    display: flex!important;
}

.software-name{
    font-weight: bold;
    margin-right: auto!important;
}

@media (max-width: 360px) {
    .software{
        font-size: .95em !important;
        font-weight: normal !important;
    }

    .software.segment{
        padding: .7em !important;
    }

    .software-name{
        font-size: 1.06em !important;
        font-weight: normal !important;
        margin-bottom: .3em !important;
    }

    .software .header{
        font-size: 1.1em !important;
    }
  }