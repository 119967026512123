.uspd-report-data .table-data {
  max-height: 600px!important;
  width: 100%;
  overflow: scroll !important;
  /* border-collapse: collapse; */
}

.line-name {
  margin-top: 1.5em !important;
  /* color: #2185d0 !important; */
}


/* .uspd-report-data-container {
  max-width: 900px !important;
  overflow-x: scroll !important;
} */

@media screen and (max-width: 600px) {
  .uspd-report-data-container {
    max-width: 900px !important;
    overflow-x: auto !important;
  }

  .table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .table td {
    padding: 0.5em 0.5em !important;
  }
}