.rep-buttons {
  padding-top: 1em !important;
}

/* .report-setting {
  min-width: 300px !important;
  height: 800px; 
} */

.report-setting {
  margin-top: 0.5em !important;
  min-width: 250px !important;
  padding: 10px !important;
  /* width: 30% !important; */
}

.report-setting-accordion .content.active {
  background-color: aliceblue !important;
}
@media only screen and (max-width: 999px) {
  [class*="report-setting"] {
    padding: 0px !important;
  }
  .report-setting {
    max-height: 700px; 
  }
}
