.monitor-factory {
  margin-top: 0.5em !important;
  min-width: 250px !important;
  height: 550px; 
  padding: 10px !important; 
}

.header-select {
  font-size: 16px !important;
}

.search {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  [class*="monitor-factory"] {
    padding: 0px !important;
  }
}
