.date-picker{
  height: 36px!important;
  /* min-width: 136px!important; */
  border: 1px solid !important;
  border-color: rgb(216, 216, 216) !important;
  border-radius: 4% !important;
  text-align: center !important;
  width: 100% !important;
}

.report-date-selector {
  text-align: center !important;
  padding-left: 1em !important;
  margin-left: auto !important;
  margin-right: auto !important;  
}

.report-date-selector ::before{
  display: none!important;
}

