.factory-group .card{
  width: 48% !important;
}

@media (max-width: 600px) {
  .factory-group .card{
    width: 100% !important;
    margin-bottom: .0em !important;
  }
}
