.soft-info {
  padding: 2em !important;
}

@media (max-width: 600px) {
  .info-page {
    padding: .3em !important;
    /* font-size: 12px !important; */
  }
}

@media (max-width: 550px) {
  .soft-info {
    display: inline-block !important;

    font-size: 12px !important;
    white-space: pre-wrap !important;
    overflow: auto !important;
    margin: .5em !important;
    padding-top: 3em !important;
    padding-bottom: 1em !important;
  }
}