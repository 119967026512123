.uspd-list.menu .item {
  margin: 0 !important;
  padding-bottom: 0 !important; 
}

/* .ui.segment {
  padding: 10px !important
} */

.current-state.segment {
  padding: 1em !important;
  height: auto !important;;
  overflow:  hidden !important;
}

.uspd-list {
  padding-left: 5px !important;
}

@media only screen and (max-width: 767px) {
  [class*="current-state"] {
    font-size: 12px !important;
  }

  .current-state.segment {
    padding: 0.3em !important;
    height: auto !important;;
    overflow:  hidden !important;
  }

  .uspd-list {
    margin: 0px !important;
  }

  .uspd-list .item{
    padding: .5em 0em  0em 0em !important;
  }
}

/* .uspd-list .item .uspd {
  padding: 10px !important
} */