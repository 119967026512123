@media (max-width: 550px) {
  .name-fatory {
    display: inline-block !important;

    font-size: 12px !important;
    white-space: pre-wrap !important;
    overflow: auto !important;
    margin: .5em !important;
  }
}
