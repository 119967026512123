.monitor-page.one {
  width: 10% !important;
}

.monitor-page.two {
  width: 70% !important;
}

.monitor-page.one {
  width: 25% !important;
}

.monitor-page.two {
  width: 75% !important;
}

.all-report.one {
  width: 25% !important;
}

.all-report.two {
  width: 75% !important;
}

