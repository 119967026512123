/* .item.uspd {
  width: auto !important;
}
.uspd {
  margin-left: auto !important;
  padding: none !important;
  width: auto !important;
}

.content-tab {
  margin: 1em;
} */

.map {
  margin-left: auto !important;
  padding: none !important;
  width: auto !important;
}

.content-mobile {
  padding: .8em 0em 1em 1em !important;
  color:#3385d0 !important;
  /* font-weight: lighter; */
  /* text-align: center !important; */
  /* margin: 0 auto !important; */
  font-size: 16px !important;
  border-radius: 10px !important;
}

.content-computer{
  padding-top: .5em !important;
}
.content-computer .menu .item{
  height: 43px !important;
}

@media only screen and (max-width: 999px) {
  [class*="content-computer"] {
    display: none !important;
  }

}