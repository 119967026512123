.line-content .details {
  /* height: 260px; */
  padding: 0 1em 1em 1em !important;
  margin: 0;
}

.line-content .details .column {
  padding: 0 1em 0 0 !important;
  width: 50% !important;
}

.line-content .details .current {
  padding: 10px !important;
  padding-right: 0.5em !important;
}

.line-content .details .accumulated {
  padding: 10px !important;
  padding-left: 0.5em !important;
}

.line-content td {
  width: 33%;
}

.first-mobile {
  margin: 0px !important;
}

.first-computer {
  margin: 0px !important;
  text-align: center !important;
}


@media only screen and (min-width: 361px) {
  .first-mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .details .divider {
    margin-top: 0.1em !important;
  }
  
  .line-content .details {
    padding: 0 1em 0em 1em !important;
    margin: 0;
  }
}

@media only screen and (max-width: 360px) {
  .details .divider {
    font-size: 12px !important;
    margin-top: 0 !important;
  }
  .first-computer,
  .second-computer {
    display: none !important
  }

  .first-mobile {
    font-size: 12px !important;
  }

  /* .table th {
    padding-left: 0.7em !important;
    padding-right: 0.7em !important;
  }
  .table td {
    padding: 0.5em 0.5em !important;
  } */
  
  .line-content .details {
    /* height: 260px; */
    padding: 0 1em 0em 1em !important;
    margin: 0;
  }
}
