.empty p {
  text-align: center;
  font-weight: bolder;
  font-size: 20px;
}

.empty .ui.image {
  margin-left: auto;
  margin-right: auto;
}
